import React, { FC, useCallback, useRef, useState } from 'react';

import { ClickOutside } from '../ClickOutside';
import { SearchBar } from './SearchBar';
import { Option, SearchResults } from './SearchResults';

export interface SearchProps {
  bordered?: boolean;
  query?: string;
  defaultValue?: string;
  onChange?: (query: string) => void;
  onSelect?: (option: Option, position: number) => void;
  onSubmit?: (query: string) => void;
  placeholder?: string;
  suggestions?: Option[];
}

export const Search: FC<SearchProps> = ({
  bordered = true,
  defaultValue,
  onChange,
  onSelect,
  onSubmit,
  placeholder,
  query,
  suggestions = [],
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [cursor, setCursor] = useState(-1);
  const [inputFocus, setInputFocus] = useState(false);

  const handleFocus = useCallback((focus: boolean) => {
    if (focus) {
      setInputFocus(true);
      return;
    }
    setCursor(-1);
  }, []);

  const handleSelect = useCallback(
    (option: Option, position: number) => {
      if (onSelect) {
        onSelect(option, position);
        setInputFocus(false);
      }
    },
    [onSelect],
  );

  const handleKeydown = useCallback(
    (ev: React.KeyboardEvent<HTMLInputElement>) => {
      if (ev.key === 'ArrowDown' && cursor + 1 < suggestions.length) {
        setCursor((prev) => prev + 1);
      }
      if (ev.key === 'ArrowUp' && cursor - 1 >= 0) {
        setCursor((prev) => prev - 1);
      }
      if (ev.key === 'Enter') {
        ev.preventDefault();
        const input = ev.currentTarget.value;
        if (suggestions?.[cursor]) {
          handleSelect(suggestions[cursor], cursor);
        } else if (onSubmit) {
          onSubmit(input);
        }
      }
    },
    [cursor, suggestions, onSubmit, handleSelect],
  );

  return (
    <div ref={wrapperRef} className="relative flex w-full flex-col">
      <ClickOutside onClickOutside={() => setInputFocus(false)}>
        <SearchBar
          defaultValue={defaultValue}
          onChange={onChange}
          onFocus={handleFocus}
          onKeydown={handleKeydown}
          placeholder={placeholder || 'Search'}
        />
        {wrapperRef.current &&
        inputFocus &&
        (suggestions.length > 0 || query) ? (
          <div
            className="absolute left-0 z-90 w-full"
            style={{
              top: `calc(${wrapperRef.current.clientHeight}px + 0.75rem)`,
            }}
          >
            <SearchResults
              bordered={bordered}
              cursor={cursor}
              onSelect={handleSelect}
              options={suggestions}
              query={query}
            />
          </div>
        ) : null}
      </ClickOutside>
    </div>
  );
};
