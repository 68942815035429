import { Button } from '@design-system';
import classNames from 'classnames';
import React from 'react';

import { useMediaQuery } from '../../../../hooks/useMediaQuery';

interface Props {
  hidden: boolean;
  id: string;
  title: string | React.ReactNode;
  isBackBtnVisible?: boolean;
  progress?: {
    count: number;
    currentIndex: number;
  };
  isIntro?: boolean;
  onBack?: () => void;
  onClose: () => void;
  isCloseBtnVisible?: boolean;
  isProgressIndicatorVisible?: boolean;
}

export const ProgressIndicator = ({
  currentStepIndex,
  stepCount,
}: {
  currentStepIndex: number;
  stepCount: number;
}) => {
  return (
    <ol
      aria-label="Steps to complete the dialog"
      className="flex justify-center gap-2 py-1.5"
    >
      {/** We assume the last step is always a confirmation / thank you step hence why we subtract 1 from `stepCount`  * */}
      {Array.from({ length: stepCount - 1 }).map((_, index) => (
        <li
          aria-current={currentStepIndex === index}
          aria-label={`Step ${index} out of ${stepCount - 1}`}
          className={classNames('h-1.5 w-1.5 rounded-full', {
            'bg-active-4': currentStepIndex === index,
            'bg-neutral-6': currentStepIndex > index,
            'bg-neutral-2': currentStepIndex < index,
          })}
        />
      ))}
    </ol>
  );
};

export const Header = (props: Props) => {
  const {
    hidden,
    id,
    isBackBtnVisible = true,
    isCloseBtnVisible = true,
    isIntro,
    isProgressIndicatorVisible,
    onBack,
    onClose,
    progress,
    title,
  } = props;
  const isMobile = useMediaQuery('sm');

  if (hidden) return null;

  return (
    <div
      className="sticky top-0 z-10 flex w-full items-center justify-between bg-white p-6 md:border-none"
      id={id}
    >
      {!isBackBtnVisible || isIntro || !onBack ? (
        <div className="h-0 w-9" />
      ) : (
        <Button
          hideLabel
          icon="ic_arrow_left"
          label="Back"
          onClick={onBack}
          size={isMobile ? 'xs' : 'sm'}
          variant="transparent"
        />
      )}
      {title && !isProgressIndicatorVisible ? (
        <p
          className={classNames(
            'bold grow px-3 text-center font-bold',
            isIntro ? 'text-md' : 'sm:text-sm md:text-md',
          )}
        >
          {title}
        </p>
      ) : null}
      {!isProgressIndicatorVisible || !progress ? null : (
        <ProgressIndicator
          currentStepIndex={progress?.currentIndex}
          stepCount={progress?.count}
        />
      )}
      {!isCloseBtnVisible ? null : (
        <Button
          hideLabel
          icon="ic_close"
          label="Close"
          onClick={onClose}
          size={isIntro || !isMobile ? 'sm' : 'xs'}
          variant="transparent"
        />
      )}
    </div>
  );
};
