import { Button, CheckboxCard, CheckboxWrapper } from '@design-system';
import { useAnalyticsEmitter } from '@utils/analytics/emitter';
import classnames from 'classnames';
import { useTranslation } from 'next-i18next';
import React, { useCallback, useEffect, useState } from 'react';

import { useFlow } from '../../../../providers';

interface Props {
  title: string;
  options: { name: string; label: string }[];
  cta: string;
  onChange?: (input: Record<string, string | boolean>) => void;
  onSubmit?: (input: Record<string, string | boolean>) => void;
  values?: { [key: string]: boolean };
  classNames?: {
    title?: string;
    wrapper?: string;
  };
}

export const MultiChoice = ({
  classNames,
  cta,
  onChange,
  onSubmit,
  options,
  title,
  values,
}: Props) => {
  const { t } = useTranslation('onboarding');
  const { goNextStep } = useFlow();
  const [emit] = useAnalyticsEmitter();

  const [selected, setSelected] = useState<{ [key: string]: boolean }>();

  useEffect(() => {
    if (values && !selected) setSelected(values);
  }, [selected, values]);

  const handleNext = useCallback(() => {
    emit('', 'clicked');
    if (onSubmit && selected) onSubmit(selected);
    goNextStep();
  }, [emit, goNextStep, onSubmit, selected]);

  return (
    <div className={classnames('flex flex-col gap-4', classNames?.wrapper)}>
      <h2 className={classnames('font-bold', classNames?.title)}>{t(title)}</h2>

      <CheckboxWrapper>
        {options.map((option) => (
          <CheckboxCard
            checked={Boolean(selected?.[option.name])}
            id={option.name}
            label={t(option.label)}
            name={option.name}
            onChange={() => {
              setSelected((prev) => ({
                ...prev,
                [option.name]: !prev?.[option.name],
              }));
              if (onChange)
                onChange({ [option.name]: !selected?.[option.name] });
            }}
            type="checkbox"
            value={option.name}
          />
        ))}
      </CheckboxWrapper>

      {onSubmit && (
        <Button
          disabled={!selected}
          fullWidth
          label={t(cta)}
          onClick={handleNext}
          size="lg"
          type="submit"
          variant="primary"
        />
      )}
    </div>
  );
};
