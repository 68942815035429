import { Icon, InputField } from '@design-system';
import React, {
  createRef,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { debounce } from 'throttle-debounce';

export type SearchBarRef = {
  update: (val: string) => void;
} | null;

export interface SearchBarProps {
  defaultValue?: string;
  delay?: number;
  inputId?: string;
  onChange?: (query: string) => void;
  onFocus?: (focus: boolean) => void;
  onKeydown?: (ev: React.KeyboardEvent<HTMLInputElement>) => void;
  placeholder: string;
}

export const SearchBar = forwardRef<SearchBarRef, SearchBarProps>(
  (
    {
      defaultValue = '',
      delay = 200,
      inputId = 'searchInput',
      onChange,
      onFocus,
      onKeydown,
      placeholder,
    },
    ref,
  ) => {
    const inputRef = createRef<HTMLInputElement>();
    const [focus, setFocus] = useState(false);
    const [state, setState] = useState(defaultValue);
    const [value, setValue] = useState(defaultValue);

    useImperativeHandle(ref, () => ({
      update: (val: string) => {
        setValue(val);
      },
    }));

    const handleUpdate = useMemo(
      () =>
        debounce(delay, (query: string) => {
          onChange?.(query);
        }),
      [delay, onChange],
    );

    const handleChange = useCallback(
      (ev: React.ChangeEvent<HTMLInputElement>) => {
        const val = ev.target.value;
        setValue(val);
        handleUpdate(val);
      },
      [handleUpdate],
    );

    useEffect(() => {
      if (onFocus) onFocus(focus);
    }, [focus, onFocus]);

    useEffect(() => {
      if (state !== '' && defaultValue === '') {
        setValue('');
        inputRef.current?.focus();
      }
      setState(defaultValue);
    }, [defaultValue, inputRef, state]);

    return (
      <InputField
        ref={inputRef}
        id={inputId}
        name={inputId}
        onBlur={() => setFocus(false)}
        onChange={handleChange}
        onFocus={() => setFocus(true)}
        onKeyDown={onKeydown}
        placeholder={placeholder}
        suffix={<Icon name="ic_search" />}
        type="search"
        value={value}
      />
    );
  },
);
