import { Button, CheckboxCard, CheckboxWrapper } from '@design-system';
import { useAnalyticsEmitter } from '@utils/analytics/emitter';
import classnames from 'classnames';
import { useTranslation } from 'next-i18next';
import React, { useCallback, useEffect, useState } from 'react';

import { useFlow } from '../../../../providers';

interface Props {
  title: string;
  options: { value: string; label: string }[];
  cta: string;
  onChange?: (input: Record<string, string>) => void;
  onSubmit?: (input: Record<string, string>) => void;
  name: string;
  value?: string | null;
  classNames?: {
    title?: string;
    wrapper?: string;
  };
}

export const SingleChoice = ({
  classNames,
  cta,
  name,
  onChange,
  onSubmit,
  options,
  title,
  value,
}: Props) => {
  const { t } = useTranslation('onboarding');
  const { goNextStep } = useFlow();
  const [emit] = useAnalyticsEmitter();

  const [selected, setSelected] = useState<string>();

  useEffect(() => {
    if (value && !selected) setSelected(value);
  }, [selected, value]);

  const handleNext = useCallback(() => {
    emit('', 'clicked');
    if (onSubmit && selected) onSubmit({ [name]: selected });
    goNextStep();
  }, [emit, goNextStep, name, onSubmit, selected]);

  return (
    <div className={classnames('flex flex-col gap-4', classNames?.wrapper)}>
      <h2 className={classnames('font-bold', classNames?.title)}>{t(title)}</h2>

      <CheckboxWrapper>
        {options.map((option) => (
          <CheckboxCard
            checked={selected === option.value}
            id={option.value}
            label={t(option.label)}
            name={option.value}
            onChange={(e) => {
              setSelected(e.currentTarget.value);
              if (onChange) {
                onChange({ [name]: e.currentTarget.value });
              }
            }}
            type="radio"
            value={option.value}
          />
        ))}
      </CheckboxWrapper>

      {onSubmit ? (
        <Button
          disabled={!selected}
          fullWidth
          label={t(cta)}
          onClick={handleNext}
          size="lg"
          type="submit"
          variant="primary"
        />
      ) : null}
    </div>
  );
};
